<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-300px">
            <div>
                <p class="title">반복 일정 삭제</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="mt-20 mb-30 pl-10">
                    <label class="dp-block"><input type="radio" v-model="pop.dtype" value="this"><span class="ml-5"> 이 일정</span></label>
                    <label class="dp-block mt-10"><input type="radio" v-model="pop.dtype" value="after"><span class="ml-5"> 이 일정 및 향후 일정</span></label>
                </div>
            </div>
            <div class="layer-btn mt-10 txt-center">
                <button @click="pop.doDelete" class="btn-default dp-inblock">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';

export default {
    name: 'StudioReservationDelPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        },
        tdate: {
            type: String,
            default: "",
        },
        deleted: {
            type: Boolean,
            default: false,
        }
    },
    emits: [ 'update:idx', 'update:deleted' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            idx   : 0,
            dtype : 'this',
            tdate : '',

            doDelete : () => {
                let params = {
                    idx   : pop.idx,
                    dtype : pop.dtype,
                    tdate : pop.tdate
                };
                axios.post('/rest/resourceManagement/delStudioReservation', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.info("삭제되었습니다.");
                        emit('update:deleted', true);
                        emit('close');
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            onClose: () => {
                emit('update:deleted', false);
                emit('close');
            }
        });
        
        onMounted(() => {
            // Mounted
            pop.idx   = props.idx;
            pop.tdate = props.tdate;
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>