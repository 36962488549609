<template>

    <div class="con-wrap">
        <StudioReservationViewPopup :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowSchedule" @close="pop.hideSchedule"></StudioReservationViewPopup>

        <CarrotTitle title="촬영실 운용현황보기">
            - 예약현황을 클릭하시면 상세 내용을 보실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
                        <button class="day btn-cal">{{ bbs.today.substring(0, 7) }}</button>
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>

                    <div class="dp-table w-100per mt-40 mb-20">
                        <select v-model="bbs.stype" @change="bbs.doSearch" class="w-100px">
                            <option value="스튜디오">스튜디오</option>
                          <!--
                            <option value="촬영실">촬영실</option>
                            <option value="녹음실">녹음실</option>
                            -->
                        </select>
                        <button @click="bbs.addSchedule()" class="btn-default float-right">스케줄 예약</button>
                    </div>

                    <CarrotCalendar :daysinfo="bbs.list" :ymd="bbs.today" @selected="pop.showSchedule"></CarrotCalendar>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotCalendar from '@/components/common/CarrotCalendar.vue'
import StudioReservationViewPopup from '@/components/popup/ResourceManagement/StudioReservationViewPopup.vue'

export default {
    layout:"ResourceManagement",
    components: {
        CarrotCalendar,
        StudioReservationViewPopup,
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            idx   : 0,
            tdate : "",
            // Popup 노출 여부
            isShowSchedule  : false,

            showSchedule : (o) => {
                if( Number(o.idx) > 0 ){
                    pop.idx   = o.idx;
                    pop.tdate = o.cdate;

                    pop.isShowSchedule = true;
                } else {
                    bbs.addSchedule(o.cdate);
                }
            },
            hideSchedule : () => {
                pop.isShowSchedule = false;
                pop.idx = 0;
                bbs.doSearch();
            },
        });

        const bbs = reactive({
            today : "",
            stype : "스튜디오",

            list : [], total : 0,

            doPrev : () => {
                let td = new Date(bbs.today);
                let nd = new Date(td.getFullYear(), td.getMonth()-1, td.getDate());
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();

                sessionStorage.setItem('StudioReservationMonth', JSON.stringify({
                    today : bbs.today
                }));
            },

            doNext : () => {
                let td = new Date(bbs.today);
                let nd = new Date(td.getFullYear(), td.getMonth()+1, td.getDate());
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();

                sessionStorage.setItem('StudioReservationMonth', JSON.stringify({
                    today : bbs.today
                }));
            },

            showDate : () => {
                sessionStorage.setItem('StudioReservationMonth', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-StudioReservationDate'
                });
            },

            addSchedule : (cdate) => {
                sessionStorage.setItem('StudioReservationMonth', JSON.stringify({
                    today : cdate?cdate:bbs.today
                }));
                if( !cdate ){
                    sessionStorage.removeItem('StudioReservationAdd');
                } else {
                    sessionStorage.setItem('StudioReservationAdd', JSON.stringify({
                        today : cdate,
                    }));
                }

                router.push({
                    name : 'ResourceManagement-StudioReservationAdd'
                });
            },

            doSearch : () => {
                let params = {
                    studio : bbs.stype,
                    tgdate : bbs.today
                };
                axios.get('/rest/resourceManagement/getStudioReservationMonth', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            let today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());
            bbs.today = today;

            let ss = sessionStorage.getItem('StudioReservationMonth');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today && new Date(ss.today) != 'Invalid Date' ) {
                    bbs.today = ss.today;
                }
            }

            bbs.doSearch();
        });

        return {bbs, pop};
    }
}
</script>

<style lang="scss" scoped>
</style>